<template>
  <div class="ProductDefaultLayout -mt-[42px] desk:grid grid-cols-12 gap-x-12 desk:mt-0 desk:mb-80 desk:pt-16">
    <!-- spalt 1 -->
    <div class="hidden desk:block flex-col col-span-3">
      <div id="ProductImageThumbnails" class="flex justify-end" />
    </div>

    <!-- spalt 2 -->
    <div class="mobOnly:w-full mobOnly:mb-24 desk:col-span-5 text-left">
      <ProductImage
        :product-item="productItem"
        :current-image-list="currentImageList"
        :active-variant="activeVariant"
      />

      <!-- * * * * * * desktop details -->
      <div class="mt-12 mobOnly:hidden">
        <ProductDetails
          :product-item="productItem"
          :active-variant="activeVariant"
        />

        <!-- * * * * desktop bundles -->
        <ProductBundles v-if="activeVariant.isIncludedInStructureArticle" :active-variant="activeVariant" />
      </div>
    </div>

    <!-- spalt 3 -->
    <div class="mobOnly:w-full desk:col-span-4 desk:pl-28">
      <div class="mobOnly:px-12">
        <!-- * * * * * * name & category -->
        <NameAndCategory
          :product-item="productItem"
          :active-variant="activeVariant"
        />
        <!-- * * * * * * structure article -->
        <div v-if="productItem.isStructureArticle && activeVariant.structureArticleProducts?.length" class="mb-12">
          <p class="type-sm-medium uppercase">{{ $t('productPage.bundles.contains') }}</p>
          <component
            :is="activeVariant.structureArticleProducts.length > 6 ? ReadMore : 'div'"
            :max-height="300"
            :as-button="true"
            :closed-text="$t('productPage.bundles.showAll', {num: activeVariant.structureArticleProducts.length})"
            :open-text="$t('productPage.bundles.showLess')"
            :class="{
              'mb-24': activeVariant.structureArticleProducts.length > 6
            }"
          >
            <div class="flex flex-col">
              <component
                :is="product.url ? NuxtLink : 'div'"
                v-for="product in activeVariant.structureArticleProducts"
                :key="product.partNo"
                :to="product.url"
                class="py-6 flex w-full"
              >
                <div class="w-28 overflow-hidden mr-12 relative">
                  <nuxt-img
                    provider="norce"
                    sizes="80px"
                    :src="product.image"
                    class="rounded-4 w-full"
                  />
                </div>
                <div class="flex justify-between flex-1 type-xs-medium">
                  <div class="w-full">
                    <span class="type-sm-medium">{{ product.quantity }} x {{ product.name }}</span>
                    <div class="text-dark">
                      {{ product.partNo }}
                    </div>
                    <div class="flex items-center" v-if="product.inStock !== undefined && product.inStock === false">
                      <div
                        class="StockStatus w-[5px] h-[5px] rounded-full mr-6 relative -top-1 bg-brightRed"
                      />
                        <span v-if="product.estimatedDelivery !== undefined && product.estimatedDelivery?.length > 0" class="text-brightRed" >
                          {{ $t('stock.tempSoldout', { date: product.estimatedDelivery }) }}
                        </span>
                        <span v-else class="text-brightRed" >
                          {{ $t('stock.soldout') }}
                        </span>
                      </div>
                  </div>
                </div>
              </component>
            </div>
          </component>
        </div>

        <!-- * * * * * * price -->
        <div class="block">
          <client-only>
            <ProductPrice
              :key="activeVariant.partNo"
              :product-item="productItem"
              :active-variant="activeVariant"
              design="standard"
            />
          </client-only>
        </div>
      </div>

      <!-- * * * * * * variant selector -->
      <VariantSelector
        :product-item="productItem"
        :active-variant="activeVariant"
        design="standard"
        @update-active-variant="(variant) => updateActiveVariant(variant)"
      />
      <div class="mobOnly:px-12">
        <!-- * * * * * * quantity and buy button -->
        <BuyBox
          v-if="activeVariant"
          :key="activeVariant.partNo"
          :active-variant="activeVariant"
          :product-item="productItem"
          design="standard"
        />

        <div
          v-if="productBackorderNotices.length && backorderNoticeTotalQuantity > 0"
          class="bg-brightYellow p-16 type-sm-medium mobOnly:mb-12"
        >
          <span v-if="productBackorderNotices[0].estimatedDeliveryDate !== ''">
            {{
              $t('product.backorderNoticeWithDate', {
                quantity: backorderNoticeTotalQuantity,
                date: productBackorderNotices[0].estimatedDeliveryDate
              })
            }}
          </span>
          <span v-else>
            {{ $t('product.backorderNotice', {quantity: backorderNoticeTotalQuantity}) }}
          </span>
        </div>

        <div
          v-if="productItem.shortDescription"
          class="desk:hidden type-sm py-6"
          v-html="productItem.shortDescription"
        />
      </div>

      <div class="desk:hidden">
        <!-- * * * * * * mobile details -->
        <ProductDetails
          mobile
          :product-item="productItem"
          :active-variant="activeVariant"
        />

        <!-- * * * * mobile bundles -->
        <ProductBundles v-if="activeVariant.isIncludedInStructureArticle" :active-variant="activeVariant" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VariantSelector from '~/components/product-page/VariantSelector.vue';
import BuyBox from '~/components/product-page/BuyBox.vue';
import ProductImage from '~/components/product-page/ProductImage.vue';
import NameAndCategory from '~/components/product-page/NameAndCategory.vue';
import ProductPrice from '~/components/product-page/ProductPrice.vue';
import ProductDetails from '~/components/product-page/ProductDetails.vue';
import ProductBundles from '~/components/product-page/ProductBundles.vue';
import { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { NorceMediaSetCodes } from '~/constants/norceCodes';
import { useUserStore } from '~/store/user';

const userStore = useUserStore();

const NuxtLink = resolveComponent('NuxtLink');
const ReadMore = resolveComponent('ReadMore');

const emit = defineEmits<{
  (e: 'updateActiveVariant', obj: ProductVariantModel): void;
}>();

const props = defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  currentImageList: NorceMediaSetCodes,
}>();

const productBackorderNotices = ref([]);

onMounted(() => {
  productBackorderNotices.value = userStore.backorderNotices.filter((i) => i.productNo === props.activeVariant.partNo);
});

const backorderNoticeTotalQuantity = computed(() => {
  return productBackorderNotices.value?.map((i) => i.quantity)?.reduce((a, c) => a + c, 0) ?? 0;
});

const updateActiveVariant = async(variant: ProductVariantModel) => {
  emit('updateActiveVariant', variant);
  productBackorderNotices.value = userStore.backorderNotices.filter((i) => i.productNo === variant.partNo);
};
</script>
